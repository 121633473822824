import _typeof from "@babel/runtime/helpers/esm/typeof";
import hash from '@emotion/hash';
import canUseDom from "rc-util/es/Dom/canUseDom";
import { removeCSS, updateCSS } from "rc-util/es/Dom/dynamicCSS";
import { Theme } from "./theme";

// Create a cache for memo concat

var resultCache = new WeakMap();
var RESULT_VALUE = {};
export function memoResult(callback, deps) {
  var current = resultCache;
  for (var i = 0; i < deps.length; i += 1) {
    var dep = deps[i];
    if (!current.has(dep)) {
      current.set(dep, new WeakMap());
    }
    current = current.get(dep);
  }
  if (!current.has(RESULT_VALUE)) {
    current.set(RESULT_VALUE, callback());
  }
  return current.get(RESULT_VALUE);
}

// Create a cache here to avoid always loop generate
var flattenTokenCache = new WeakMap();

/**
 * Flatten token to string, this will auto cache the result when token not change
 */
export function flattenToken(token) {
  var str = flattenTokenCache.get(token) || '';
  if (!str) {
    Object.keys(token).forEach(function (key) {
      var value = token[key];
      str += key;
      if (value instanceof Theme) {
        str += value.id;
      } else if (value && _typeof(value) === 'object') {
        str += flattenToken(value);
      } else {
        str += value;
      }
    });

    // Put in cache
    flattenTokenCache.set(token, str);
  }
  return str;
}

/**
 * Convert derivative token to key string
 */
export function token2key(token, salt) {
  return hash("".concat(salt, "_").concat(flattenToken(token)));
}
var randomSelectorKey = "random-".concat(Date.now(), "-").concat(Math.random()).replace(/\./g, '');

// Magic `content` for detect selector support
var checkContent = '_bAmBoO_';
function supportSelector(styleStr, handleElement, supportCheck) {
  if (canUseDom()) {
    var _getComputedStyle$con, _ele$parentNode;
    updateCSS(styleStr, randomSelectorKey);
    var _ele = document.createElement('div');
    _ele.style.position = 'fixed';
    _ele.style.left = '0';
    _ele.style.top = '0';
    handleElement === null || handleElement === void 0 ? void 0 : handleElement(_ele);
    document.body.appendChild(_ele);
    if (process.env.NODE_ENV !== 'production') {
      _ele.innerHTML = 'Test';
      _ele.style.zIndex = '9999999';
    }
    var support = supportCheck ? supportCheck(_ele) : (_getComputedStyle$con = getComputedStyle(_ele).content) === null || _getComputedStyle$con === void 0 ? void 0 : _getComputedStyle$con.includes(checkContent);
    (_ele$parentNode = _ele.parentNode) === null || _ele$parentNode === void 0 ? void 0 : _ele$parentNode.removeChild(_ele);
    removeCSS(randomSelectorKey);
    return support;
  }
  return false;
}
var canLayer = undefined;
export function supportLayer() {
  if (canLayer === undefined) {
    canLayer = supportSelector("@layer ".concat(randomSelectorKey, " { .").concat(randomSelectorKey, " { content: \"").concat(checkContent, "\"!important; } }"), function (ele) {
      ele.className = randomSelectorKey;
    });
  }
  return canLayer;
}
var canWhere = undefined;
export function supportWhere() {
  if (canWhere === undefined) {
    canWhere = supportSelector(":where(.".concat(randomSelectorKey, ") { content: \"").concat(checkContent, "\"!important; }"), function (ele) {
      ele.className = randomSelectorKey;
    });
  }
  return canWhere;
}
var canLogic = undefined;
export function supportLogicProps() {
  if (canLogic === undefined) {
    canLogic = supportSelector(".".concat(randomSelectorKey, " { inset-block: 93px !important; }"), function (ele) {
      ele.className = randomSelectorKey;
    }, function (ele) {
      return getComputedStyle(ele).bottom === '93px';
    });
  }
  return canLogic;
}